import { message } from "antd";
import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAuth } from "../provider/AuthProvider";
import PrivateRoutes from "./privateRoute";
import PublicRoutes from "./publicRoute";

const LoadingComponent = () => {
  React.useEffect(() => {
    const messageKey = 'loadingKey';
    message.loading({
      content: 'Loading...',
      key: messageKey,
      duration: 0
    });

    return () => {
      message.destroy(messageKey);
    };
  }, []);

  return <div></div>;
};

const RequireAuth = ({ children, allowGuest = true }) => {
  const { token, isGuest } = useAuth();

  if (!token && !isGuest) {
    return <Navigate to="/category-listing" replace />;
  }

  if (isGuest && !allowGuest) {
    message.warning("Please login to access this feature");
    return <Navigate to="/login" replace />;
  }

  return children;
};

const AppRoute = () => {
  // const { isGuest } = useAuth();

  const getGuestAccessibility = (path) => {
    const restrictedPaths = [
      '/my-bookings',
      '/profile',
      '/profile/detail',
      '/profile/change-password',
    ];

    return !restrictedPaths.some(restrictedPath =>
      path.match(new RegExp(restrictedPath.replace('*', '.*')))
    );
  };

  return (
    <Suspense fallback={<LoadingComponent />}>
      <Routes>
        <Route path="/" element={<Navigate to="/dashboard" replace />} />

        {PublicRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={<route.component />}
            exact={route.exact}
          />
        ))}

        {PrivateRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              <RequireAuth allowGuest={getGuestAccessibility(route.path)}>
                <route.component />
              </RequireAuth>
            }
            exact={route.exact}
          />
        ))}
      </Routes>
    </Suspense>
  );
};

export default AppRoute;