import React, { createContext, useState } from "react";
import languagesObject from "./languagesObject";

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [languageSelected, setLanguageSelected] = useState("en");

  const changeLanguage = (lang) => {
    setLanguageSelected(lang);
  };

  return (
    <AppContext.Provider
      value={{
        languageSelected,
        changeLanguage,
        translations: languagesObject,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
