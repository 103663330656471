import "./App.css";
import AppRoute from "./routes/appRoute";
import AuthProvider from "./provider/AuthProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <AppRoute />
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
