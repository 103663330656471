import "./App.css";
import AppRoute from "./routes/appRoute";
import AuthProvider from "./provider/AuthProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AppProvider } from "./AppContext";

const queryClient = new QueryClient();

function App() {
  return (
    <AppProvider>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <AppRoute />
        </AuthProvider>
      </QueryClientProvider>
    </AppProvider>
  );
}

export default App;
