const languagesObject = {
  id: {
    space: {
      selectLocation: "Pilih Lokasi",
      selectSpace: "Pilih Ruangan",
    },
  },
  en: {
    space: {
      selectLocation: "Select Location",
      selectSpace: "Select Space",
    },
  },
};

export default languagesObject;
