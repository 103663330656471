import { lazy } from "react";

const PublicRoutes = [
  {
    path: "/login",
    component: lazy(() => import("../pages/Auth/Login")),
    exact: true,
  },
  {
    path: "/register",
    component: lazy(() => import("../pages/Auth/Register")),
    exact: true,
  },
  {
    path: "/forgot-password",
    component: lazy(() => import("../pages/Auth/ForgotPassword")),
    exact: true,
  },
  {
    path: "/dashboard",
    component: lazy(() => import("../pages/Home/Home")),
    exact: true,
  },
  {
    path: "/category-listing",
    component: lazy(() => import("../pages/Home/CategoryListing")),
    exact: true,
  },
  {
    path: "/product/:id",
    component: lazy(() => import("../pages/Home/Product")),
    exact: true,
  },
  {
    path: "/product/:id/booking-guest",
    component: lazy(() => import("../pages/Home/BookingGuest")),
    exact: true,
  },
  {
    path: "/product/:id/term-condition",
    component: lazy(() => import("../pages/Home/TermCondition")),
    exact: true,
  },
  {
    path: "/product/:id/personal-data",
    component: lazy(() => import("../pages/Home/PersonalData")),
    exact: true,
  },
  {
    path: "/product/:id/email-verification",
    component: lazy(() => import("../pages/Home/EmailVerification")),
    exact: true,
  },
  {
    path: "/promotion",
    component: lazy(() => import("../pages/Promotion/Promotion")),
    exact: true,
  },
  {
    path: "/promotion/:id",
    component: lazy(() => import("../pages/Promotion/PromotionDetails")),
    exact: true,
  },
  {
    path: "/services",
    component: lazy(() => import("../pages/ServiceBooking/ServiceBooking")),
    exact: true,
  },
  {
    path: "/meeting-room-packages",
    component: lazy(() => import("../pages/Package/MeetingRoomPackage")),
    exact: true,
  },
  {
    path: "/meeting-room-packages/booking/:id",
    component: lazy(() => import("../pages/Package/MeetingRoomBooking")),
    exact: true,
  },
  {
    path: "/meeting-room-packages/booking/:id/confirmed",
    component: lazy(() => import("../pages/Package/MeetingRoomConfirmed")),
    exact: true,
  },
  {
    path: "/meeting-room-packages/booking/:id/details",
    component: lazy(() => import("../pages/Package/MeetingRoomDetails")),
    exact: true,
  },
  {
    path: "/news",
    component: lazy(() => import("../pages/News/News")),
    exact: true,
  },
];

export default PublicRoutes;
