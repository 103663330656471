export const setAuthLocalStorageData = (method, userData, refreshToken, accessToken) => {
  if (method === "set" && userData && refreshToken && accessToken) {
    localStorage.setItem("access_token", accessToken);
    localStorage.setItem("refresh_token", refreshToken);
    localStorage.setItem("user", JSON.stringify(userData));
  }
  if (method === "remove") {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("user");
  }
};

export const getAuthToken = () => {
  return localStorage.getItem("access_token");
};