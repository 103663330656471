import { lazy } from "react";

const PublicRoutes = [
  {
    path: "/login",
    component: lazy(() => import("../pages/Auth/Login")),
    exact: true,
  },
  {
    path: "/verif-email",
    component: lazy(() => import("../pages/Auth/otp/VerificationEmail")),
    exact: true,
  },
  {
    path: "/verif-password",
    component: lazy(() => import("../pages/Auth/otp/VerificationPassword")),
    exact: true,
  },
  {
    path: "/login",
    component: lazy(() => import("../pages/Auth/Login")),
    exact: true,
  },
  {
    path: "/register",
    component: lazy(() => import("../pages/Auth/Register")),
    exact: true,
  },
  {
    path: "/forgot-password",
    component: lazy(() => import("../pages/Auth/ForgotPassword")),
    exact: true,
  },
  // {
  //   path: "/workspace",
  //   component: lazy(() => import("../pages/Home/Home")),
  //   exact: true,
  // },
  {
    path: "/workspace",
    component: lazy(() => import("../pages/Space/Spaces")),
    exact: true,
  },
  {
    path: "/workspace/search",
    component: lazy(() => import("../pages/Home/SearchResult")),
    exact: true,
  },
  {
    path: "/workspace/offices",
    component: lazy(() =>
      import("../pages/Home/CategoryListing/CategoryListingOffice")
    ),
    exact: true,
  },
  {
    path: "/workspace/co-working-space",
    component: lazy(() =>
      import("../pages/Home/CategoryListing/CategoryListingCoWorkingSpace")
    ),
    exact: true,
  },
  {
    path: "/workspace/meeting-rooms",
    component: lazy(
      () =>
        import("../pages/Home/CategoryListing/CategoryListingCoWorkingSpace")
      // import("../pages/Home/CategoryListing/CategoryListingMeeting")
    ),
    exact: true,
  },
  {
    path: "/workspace/board-rooms",
    component: lazy(() =>
      import("../pages/Home/CategoryListing/CategoryListingBoard")
    ),
    exact: true,
  },
  {
    path: "/workspace/class-rooms",
    component: lazy(() =>
      import("../pages/Home/CategoryListing/CategoryListingClass")
    ),
    exact: true,
  },
  {
    path: "/workspace/seminar-rooms",
    component: lazy(() =>
      import("../pages/Home/CategoryListing/CategoryListingSeminar")
    ),
    exact: true,
  },
  {
    path: "/workspace/theathre-rooms",
    component: lazy(() =>
      import("../pages/Home/CategoryListing/CategoryListingTheathre")
    ),
    exact: true,
  },
  {
    path: "/product/:id",
    component: lazy(() => import("../pages/Home/Product")),
    exact: true,
  },
  {
    path: "/product/:id/booking-guest",
    component: lazy(() => import("../pages/Home/BookingGuest")),
    exact: true,
  },
  {
    path: "/product/:id/term-condition",
    component: lazy(() => import("../pages/Home/TermCondition")),
    exact: true,
  },
  {
    path: "/product/:id/personal-data",
    component: lazy(() => import("../pages/Home/PersonalData")),
    exact: true,
  },
  {
    path: "/product/:id/email-verification",
    component: lazy(() => import("../pages/Home/EmailVerification")),
    exact: true,
  },
  {
    path: "/promotion",
    component: lazy(() => import("../pages/Promotion/Promotion")),
    exact: true,
  },
  {
    path: "/promotion/:id",
    component: lazy(() => import("../pages/Promotion/PromotionDetails")),
    exact: true,
  },
  {
    path: "/news/:id",
    component: lazy(() => import("../pages/News/NewsDetails")),
    exact: true,
  },
  {
    path: "/allspaces",
    component: lazy(() => import("../pages/Space/Spaces")),
    exact: true,
  },
  {
    path: "/services",
    component: lazy(() => import("../pages/ServiceBooking/ServiceBooking")),
    exact: true,
  },
  {
    path: "/services/:id",
    component: lazy(() => import("../pages/ServiceBooking/ServiceDetails")),
    exact: true,
  },
  {
    path: "/partner/:id",
    component: lazy(() => import("../pages/CorporatePartner/PartnerDetails")),
    exact: true,
  },
  {
    path: "/meeting-room-packages",
    component: lazy(() => import("../pages/Package/MeetingRoomPackage")),
    exact: true,
  },
  {
    path: "/meeting-room-packages/booking/:id",
    component: lazy(() => import("../pages/Package/MeetingRoomBooking")),
    exact: true,
  },
  {
    path: "/meeting-room-packages/booking/:id/confirmed",
    component: lazy(() => import("../pages/Package/MeetingRoomConfirmed")),
    exact: true,
  },
  {
    path: "/meeting-room-packages/booking/:id/details",
    component: lazy(() => import("../pages/Package/MeetingRoomDetails")),
    exact: true,
  },
  {
    path: "/news",
    component: lazy(() => import("../pages/News/News")),
    exact: true,
  },
];

export default PublicRoutes;
