import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import AuthRoutes from "./authRoute";
import ProtectedRoutes from "./privateRoute";
import { useAuth } from "../provider/AuthProvider";

const RequireAuth = ({ children }) => {
  const { token } = useAuth();
  return token ? children : <Navigate to="/login" replace />;
};
const AppRoute = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        {AuthRoutes.map((route, index) => (
          <Route key={index} path={route.path} element={<route.component />} exact={route.exact} />
        ))}
        {ProtectedRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              <RequireAuth>
                <route.component />
              </RequireAuth>
            }
            exact={route.exact}
          />
        ))}
      </Routes>
    </Suspense>
  );
};

export default AppRoute;
