import { lazy } from "react";

const AuthRoutes = [
  {
    path: "/login",
    component: lazy(() => import("../pages/Auth/Login")),
    exact: true,
  },
  {
    path: "/register",
    component: lazy(() => import("../pages/Auth/Register")),
    exact: true,
  },
];

export default AuthRoutes;
