import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setAuthLocalStorageData } from "../utils/storage";

const AuthContext = createContext(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({
    name: "",
    email: "",
  });

  const [token, setToken] = useState(
    localStorage.getItem("access_token") || ""
  );

  const navigate = useNavigate();

  const login = (data) => {
    const { name, email, need_email_verify, token } = data;
    const { access_token, refresh_token } = token;

    const user = { name, email, need_email_verify };
    setAuthLocalStorageData(
      "set",
      JSON.stringify(user),
      refresh_token,
      access_token
    );
    setToken(access_token);
    setUser(user);
    navigate("/");
  };

  const updateProfileAuth = (data) => {
    setUser((prevUser) => ({ ...prevUser, ...data }));
  };

  const register = ({ data, token }) => {
    setUser(data);
    setToken(token);
    navigate(-1 || "/");
  };

  const logout = () => {
    setUser({ name: "", email: "" });
    setToken("");

    setAuthLocalStorageData("remove");
    navigate("/login");
  };

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    const storedToken = localStorage.getItem("access_token");
    if (storedUser) setUser(JSON.parse(storedUser));
    if (storedToken) setToken(storedToken);
  }, []);

  return (
    <AuthContext.Provider
      value={{ token, user, login, logout, register, updateProfileAuth }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
